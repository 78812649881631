import Link from "next/link";
import React, { Fragment } from "react";
import styled from "styled-components";

import ChevronDown from "@/components/icons/ChevronDown/ChevronDown";
import ChevronUp from "@/components/icons/ChevronUp/ChevronUp";
import { MenuItem } from "@/components/layout/Header/Header";
import HeaderSubmenu from "@/components/layout/Header/HeaderSubmenu/HeaderSubmenu";
import { P } from "@/components/typography";
import { COLORS } from "@/styles/theme";

type Props = {
  selected?: MenuItem;
  menuCols: MenuItem[];
  onChange: (x: MenuItem) => void;
};

const StyledNav = styled.nav`
  ul {
    display: flex;
    align-items: center;
    li {
      position: relative;
      // min-width: 120px;
      // max-width: 120px;
      display: flex;
      align-items: center;
      margin-right: 32px;
      cursor: pointer;
      svg {
        // position: absolute;
        // right: 0;
      }
      p {
        margin-right: 8px;
      }

      &.selected {
        font-weight: 700;
        p {
          color: black;
        }
      }
    }
  }

  @media (min-width: 1200px) {
    ul {
      li {
        margin-right: 44px;
      }
    }
  }

  @media ${({ theme }) => theme.devices.laptop} {
  }
`;

export default function HeaderNav({ selected, onChange, menuCols }: Props) {
  const hasNoUrl = (menu: MenuItem) => menu.url === "#";
  return (
    <StyledNav>
      <ul>
        {menuCols.map((menu) => (
          <Fragment key={menu.id}>
            <li
              className={selected?.id === menu.id ? "selected" : ""}
              onClick={() => onChange(menu)}
            >
              {!hasNoUrl(menu) && (
                <div
                  className="w-full rounded-[8px] px-1"
                  style={{ backgroundColor: COLORS.primary.normal }}
                >
                  <Link href={menu.url}>
                    <P className="m-[6px]">{menu.name}</P>
                  </Link>
                </div>
              )}
              {hasNoUrl(menu) && <P>{menu.name}</P>}{" "}
              {hasNoUrl(menu) && (
                <div className="w-[16px]">
                  {selected?.id === menu.id ? <ChevronUp /> : <ChevronDown />}
                </div>
              )}
              {selected?.id === menu.id && hasNoUrl(menu) && (
                <HeaderSubmenu
                  selected={selected?.name}
                  categories={selected?.children}
                />
              )}
            </li>
            <>
              {/* {selected?.id === menu.id && (
                <HeaderSubmenu
                  selected={selected?.name}
                  categories={selected?.children}
                />
              )} */}
            </>
          </Fragment>
        ))}
      </ul>
    </StyledNav>
  );
}
